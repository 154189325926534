import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import SEO from "../components/seo"
import truck from "../images/misc/truck-with-boxes.svg"

const Truck = styled.img`
  height: auto;
  width: 100%;
  display: block;
  margin: 0 auto;
  max-width: 350px;
`

const NotFoundPage = () => (
  <>
    <SEO title="404: pagina is verhuisd" />
    <section className="pb-5">
      <div className="container-fluid">
        <div className="row align-items-end">
          <div className="col-12 col-md-6">
            <h1 className="font-weight-light">Oeps</h1>
            <p className="mb-3">Het lijkt erop dat deze pagina verhuisd is.</p>
            <div className="d-inline-flex flex-column text-center">
              <a className="btn btn-offer btn-primary mb-2" href="/offerte">
                Bereken jouw verhuizing
              </a>
              <a
                className="link link-secondary"
                href="https://wa.me/31622441648?text="
              >
                Of Whatsapp met ons
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <Link to="/">
              <Truck src={truck} alt="Verhuizen met verhuisdeal24" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default NotFoundPage
